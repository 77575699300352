import { message } from 'antd';
import type { BaseData, User } from 'egenie-common';
import { request } from 'egenie-common';
import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import { action, computed, observable } from 'mobx';
import { api, checkLoginStatus, formatDecimal, getDateInterval, getPrice, replacePath } from '../../utils';
import { LaunchStore } from '../components';
import { RightSiderStore } from '../components/rightSider/store';
import type { IBaoStyle } from '../homePage/interface';
import DownloadStore from './modal/download/store';
import type { AppGoodsStatInfoVo, ColorListItem, ColorSkuInfo, GoodsBaseVo, GoodsPicGroup, GoodsSkuInfo, GoodsSkuVo, MallShopSimpleVo, StyleDetailResponse } from './types';

export const ColorMap = [
  '#1978ff',
  '#f2270a',
  '#a419ff',
  '#02c190',
  '#ff9948',
];

export default class Store {
  constructor() {
    const params = window.location.href.split('?')[1];
    const search = new URLSearchParams(params);
    this.goodsId = Number(search.get('goodsId'));
    this.dynamicId = search.get(('dynamicId')) || '';
    this.initPage();
  }

  @observable public launchStore = new LaunchStore({ parent: this });

  @observable public downloadStore = new DownloadStore({ parent: this });

  @observable public rightSiderStore = new RightSiderStore({ parent: this });

  @observable public pageLoading = false;

  @observable public lunchLoading = false; // 一键铺货loading

  @observable public addStyleLoading = false; // 加入我的款式loading

  @observable public addCartLoading = false; // 加入进货车loading

  @observable public downloadLoading = false; // 下载素材loading

  @observable public goodsId: number;

  @observable public activityPrice: number;

  @observable public discountPrice: string;

  @observable public minWholePrice: number; // 进货价

  @observable public maxActivityPrice: number;// 最大合同价

  @observable public maxDiscountPrice: number;// 最大专享价

  @observable public maxWholePrice: number; // 最大进货价

  @observable public gmsGoodsId: number;

  @observable public dynamicId: number | string;

  @observable public mainPics: string[] = [];

  @observable public picUrl = ''; // 大图url

  @observable public colorCode = ''; // 颜色code

  @observable public collected: boolean; // 收藏

  @observable public doesAddStyle = false;// 是否已加入我的款式

  @observable public skuList: GoodsSkuInfo[] = [];

  @observable public setIntervalInstance; // 活动倒计时

  @observable public isActive = 'init'; // 活动是否开始

  @observable public countDownTime = {}; // 活动倒计时时间

  @observable public stallNewStyleList = [];

  @observable public stallNewStyleListRef;

  @observable public isSingleProps = false;

  @observable public propsName = '尺码';

  @observable public propsCode = [];

  @action public setStallNewStyleListCarousel = (el: unknown): void => {
    this.stallNewStyleListRef = el;
  };

  @action public mainPre = (e: unknown, type: string | number): void => {
    this.stallNewStyleListRef?.prev();
  };

  @action public mainNext = (e: unknown, type: string | number): void => {
    this.stallNewStyleListRef?.next();
  };

  @observable public goodsBaseInfo: Partial<GoodsBaseVo> = {
    goodsName: '',
    goodsNo: '',
    salePrice: 0,
    discountPrice: 0,
    ecommerceControlPrice: 0,
    ownCustomer: false,
  }; // 款式基础信息

  @observable public mallShopSimpleVo: MallShopSimpleVo = {
    latestNewGoodsNum: 0, // 近七日上新
    mainCategory: '', // 主营
    totalGoodsNum: 0, // 总共款式
    vendorId: '', // 档口id
    vendorName: '', // 档口名称
    vendorLogo: '', // 档口logo
    qualityLabelList: [],
    warehouseId: '',
    warehouseName: '',
    unionFlag: 0,
  }; // 档口基本信息

  @observable public liveGoodsVo = {
    brokerageRate: 0, // 佣金比例
    fastReflexes: 0, // 快反时间（天）
    floorId: '', // 楼层id
    floorName: '', // 楼层名称
    goodsLivePrice: 0, // 直播价
    liveGoodsId: '',
    sellingPointLabelCode: 0, // 卖点标签
    sellingPointLabelName: '', // 卖点标签名称
    sort: 0, // 顺序
    stockNum: 0, // 库存
  };

  @observable public putAwayTime = ''; // 上款时间

  // sku颜色
  @observable public colorList: ColorListItem[] = [];

  @observable public recommends: AppGoodsStatInfoVo = { // 推荐指数
    starsNum: 0, // 评分
    recommendVoList: [],
  };

  @observable public businessProps: Record<string, string> = {};

  @observable public goodsContentUrl = ''; // 富文本详情

  @observable public goodsPicGroup: GoodsPicGroup[] = []; // 套图组

  @observable public videoUrl = ''; // 主图视频

  @observable public currentPicGroup = null; // 当前套图id

  @observable public currentSizeList: ColorSkuInfo[] = []; // 当前选中颜色的尺码信息

  @observable public promotionPreviewVo; // 活动详情

  @observable public afterSale = { // 售后信息
    returnable: 0,
    returnRate: 0,
    returnPeriod: 15,
    protectRate: 0.1,
    deliverWarehouseName: '',
  };

  @observable public labelList: string[] = [];

  @observable public isErpAccount = false; // 是否是erp账号

  @observable public similarStyleList = [];

  @observable public virtualSaleNum = 0;

  @observable public virtualSelectNum = 0;

  @observable public status = null;

  // 展示的总价格
  @computed
  public get totalPrice() {
    let totalPrice = 0;
    this.colorList.forEach((v, i) => {
      v.colorSkuInfo?.forEach((el) => {
        totalPrice += (el.number * el.price);
      });
    });
    return formatDecimal(totalPrice);
  }

  // 展示的总数量
  @computed
  public get totalNum() {
    let totalNum = 0;
    this.colorList.forEach((v, i) => {
      v.colorSkuInfo?.forEach((el) => {
        totalNum += el.number;
      });
    });
    return totalNum;
  }

  // 初始化
  public initPage = async(): Promise<void> => {
    await this.getDetail();
    this.getAccountType();
  };

  // 处理款式详情参数;
  @action public getDetail = async(): Promise<void> => {
    try {
      const res = await this.queryGoodsParams();
      const {
        businessProps,
        collectionFlag,
        goodsId,
        goodsBaseVo,
        goodsPicVo,
        goodsContent,
        discountPrice,
        maxDiscountPrice,
        minWholePrice,
        maxWholePrice,
        maxActivityPrice,
        activityPrice,
        putAwayTime,
        appGoodsStatInfoVo,
        goodsSkuVos,
        mallShopSimpleVo,
        goodsSimpleVos,
        matchStatus,
        gmsGoodsId,
        goodsPicGroupShowVoList,
        vendorShopId,
        status,
        promotionPreviewVo,
        returnPeriod,
        deliverWarehouseName,
        returnRate,
        returnable,
        protectRate,
        marketLabelName,
        virtualSaleNum,
        virtualSelectNum,
        doesAddStyle,
        liveGoodsVo,
      } = res.data;
      document.title = goodsBaseVo.goodsName;
      this.convertData(res.data);
      Object.assign(this, {
        status,
        goodsId,
        mainPics: goodsPicVo.mainPics && goodsPicVo.mainPics.length > 0 ? goodsPicVo.mainPics.map((item) => item.url) : [],
        goodsBaseInfo: goodsBaseVo,
        mallShopSimpleVo,
        collected: Boolean(collectionFlag),
        gmsGoodsId,
        matchStatus,
        businessProps: JSON.parse(businessProps),
        goodsContentUrl: goodsContent,
        minWholePrice,
        discountPrice,
        activityPrice,
        maxDiscountPrice,
        maxWholePrice,
        maxActivityPrice,
        putAwayTime,
        goodsSimpleVos,
        goodsSkuVos,
        recommends: appGoodsStatInfoVo,
        goodsPicGroup: goodsPicGroupShowVoList ? goodsPicGroupShowVoList : [],
        vendorShopId,
        sellStatus: status,
        promotionPreviewVo,
        doesAddStyle,
        afterSale: {
          returnPeriod,
          returnRate,
          returnable,
          protectRate,
          deliverWarehouseName,
        },
        videoUrl: goodsPicVo.videoUrl,
        virtualSaleNum,
        virtualSelectNum,
        liveGoodsVo,
      });
      this.picUrl = this.mainPics?.[0];
      this.getStallNewList(goodsId, mallShopSimpleVo?.vendorId);
      this.getSimilarStyleList(goodsId, goodsPicVo?.mainPics?.[0]?.url);
      this.getActiveCountDown(promotionPreviewVo);
      this.handleSkuInfo(goodsSkuVos);
      if (!this.currentPicGroup) {
        this.currentPicGroup = goodsPicGroupShowVoList ? goodsPicGroupShowVoList[0].picGroupId : null;
      }

      // 标签赋值
      if (marketLabelName) {
        Object.assign(this.mallShopSimpleVo, { qualityLabelList: [marketLabelName]});
      }

      if (mallShopSimpleVo?.qualityLabelList) {
        Object.assign(this.mallShopSimpleVo, {
          qualityLabelList: [
            marketLabelName,
            ...mallShopSimpleVo?.qualityLabelList,
          ],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  @action public getStallNewList = (goodsId: number, vendorId: string) => {
    request<BaseData<IBaoStyle[]>>({
      url: '/api/mall/rest/square/anon/vendor/latest',
      params: {
        num: 12,
        goodsId,
        vendorId,
      },
    })
      .then((res) => {
        this.stallNewStyleList = this.getStyleImgs(res.data || []);
      })
      .catch(() => {
        this.stallNewStyleList = [];
      });
  };

  @action public getSimilarStyleList = (goodsId: number, pirUrl: string) => {
    request<BaseData<IBaoStyle[]>>({
      url: '/api/mall/rest/square/anon/picSearchV2',
      method: 'post',
      data: {
        pirUrl,
        goodsId,
      },
    })
      .then((res) => {
        this.similarStyleList = (res.data || []).slice(0, 6);
      })
      .catch(() => {
        this.similarStyleList = [];
      });
  };

  @action public getStyleImgs = (arr): IBaoStyle[][] => {
    // arr是空，或者数量小于4或者是4的倍数，直接返回[[]]
    const arrTmp = [];
    if (arr.length <= 4) {
      return [arr];
    } else {
      for (let i = 0; i < arr.length; i += 4) {
        arrTmp.push(arr.slice(i, i + 4));
      }
      return arrTmp;
    }
  };

  @action private getAccountType = async(): Promise<void> => {
    const loginStatus = await checkLoginStatus();
    if (!loginStatus) {
      return;
    }
    const userInfo: User = await request({ url: api.user });
    if (userInfo.tenantType?.includes('100001')) {
      this.isErpAccount = true;
    }
  };

  // 获取款式参数
  @action public queryGoodsParams = async(): Promise<BaseData<StyleDetailResponse>> => {
    const picGroupId = this.currentPicGroup ? this.currentPicGroup : '';
    const dynamicId = this.dynamicId ? this.dynamicId : '';
    const url = `${api.getGoodsHead}${this.goodsId}/pos/recommend/detail/info?goodsVendorDynamicId=${dynamicId}&picGroupId=${picGroupId}`;
    const res = await request<BaseData<StyleDetailResponse>>({ url });
    return Promise.resolve(res);
  };

  // 处理颜色和尺码
  @action private convertData = (res: StyleDetailResponse): void => {
    const {
      goodsSkuVos,
      goodsPropPicVos,
      saleProps,
    } = res;
    this.isSingleProps = saleProps?.length <= 1 || false;
    if (this.isSingleProps && saleProps?.length === 1) {
      this.propsName = saleProps[0].propsName;
      this.propsCode = [
        '',
        saleProps[0].propsCode,
      ];
    } else if (goodsSkuVos.length > 0) {
      const goodsSpec = goodsSkuVos[0]?.goodsSpec.split(';');
      const goodsSpecCode = goodsSkuVos[0]?.goodsSpecCode.split(';');
      this.propsCode = [goodsSpecCode[0]?.split(':')[0]];
      if (goodsSpec.length > 1) {
        this.propsCode.push(goodsSpecCode[1]?.split(':')[0]);
      }
    }
    let colorList: ColorListItem[] = goodsSkuVos.map((item) => {
      if (!this.isSingleProps && this.propsCode[1] === '1627207') {
        return {
          label: item.goodsSpec.split(/[:]|;/)[3].trim(),
          code: item.goodsSpecCode.split(/[:]|;/)[3], // goodsSpecCode格式为颜色在前，尺码在后, 例如 "1627207:28335;20509:28381"
        };
      }
      return {
        label: item.goodsSpec.split(/[:]|;/)[1].trim(),
        code: item.goodsSpecCode.split(/[:]|;/)[1], // goodsSpecCode格式为颜色在前，尺码在后, 例如 "1627207:28335;20509:28381"
      };
    });
    console.log('this.isSingleProps...', colorList);

    colorList = this.unique(colorList); // 去重
    for (let i = 0; i < colorList.length; i++) {
      const item = goodsPropPicVos.find((el) => (el.properties ? el.properties.split(/[:]|;/)[1] === colorList[i].code : false));
      if (item) {
        colorList[i].url = item.url || item.smallPicUrl;
      }
    }
    colorList.sort((a, b) => {
      return (a.url ? -1 : 1);
    }); // 排序，有图的在前

    Object.assign(this, {
      colorList,
      colorCode: colorList[0]?.code,
    });
  };

  // 去重
  private unique = (arr: ColorListItem[]): ColorListItem[] => {
    const newArr = [];
    for (const i in arr) {
      if (newArr.findIndex((el) => el.code === arr[i].code) === -1) {
        newArr.push(arr[i]);
      }
    }
    return newArr;
  };

  // 打开下载弹窗
  @action public openDownload = (): void => {
    this.downloadStore.onModalShow(this.goodsId, this.dynamicId, 'front');
  };

  // 加入我的款式
  @action public addMyStyle = (): void => {
    this.addStyleLoading = true;
    request<BaseData<string>>({
      url: '/api/mall/rest/goods/addStyle',
      method: 'GET',
      params: { goodsId: this.goodsId },
    })
      .then((res) => {
        // 重新请求
        this.queryGoodsParams()
          .then((res) => {
            const { doesAddStyle } = res.data;
            this.doesAddStyle = Boolean(doesAddStyle);
            message.success('加入成功');
            this.rightSiderStore.getMyStyleWaitNum();
          });
      })
      .finally(() => {
        this.addStyleLoading = false;
      });
  };

  // 下载二维码
  @action public downloadQRcode = (): void => {
    const canvas2 = document.createElement('canvas');
    const qrcodeCanvas = document.getElementById('qrcodeCanvas');
    const w = parseInt(window.getComputedStyle(qrcodeCanvas).width, 10);
    const h = parseInt(window.getComputedStyle(qrcodeCanvas).height, 10);
    canvas2.width = w * 1.2;
    canvas2.height = h * 1.2;
    const context = canvas2.getContext('2d');
    context.scale(1, 1);

    // html转化成canvas
    html2canvas(qrcodeCanvas, {
      canvas: canvas2,
      scale: 1.2,
      useCORS: true,
    })
      .then((canvas) => {
        // const name = `${this.styleBaseInfo.sellerOuterNo} ${this.styleBaseInfo.goodsName}.png`;
        const name = 'qrcode.png';
        canvas.toBlob((blob) => {
          FileSaver.saveAs(blob, name);
        });
      });
  };

  // 收藏或取消
  @action public collectionOrCancel = (): void => {
    request<BaseData<unknown>>({
      url: api.collectionOrNot,
      method: 'post',
      data: {
        dynamicId: this.dynamicId,
        goodsId: this.goodsId,
        status: this.collected ? 0 : 1,
      },
    })
      .then((res) => {
        this.collected = !this.collected;
        message.success(this.collected ? '收藏成功' : '已取消收藏');

        // 重新请求;
        // this.queryGoodsParams();
      });
  };

  // 获取sku库存信息
  // @action public getGoodsSkuInfo = async(): Promise<void> => {
  //   const res = await request<BaseData<GoodsSkuInfo>>({
  //     url: api.getGoodsSkuInfo,
  //     params: { id: this.goodsId },
  //   });
  //   const { goodsSkuVos } = res.data;
  //   this.handleSkuInfo(goodsSkuVos);
  // };

  // 处理suk信息
  @action private handleSkuInfo = (skuList: GoodsSkuVo[]) => {
    try {
      const skuAndPrice: Record<string, ColorSkuInfo[]> = {};
      skuList.forEach((item) => {
        const [
          color,
          size,
        ] = item.goodsSpec.split(';')
          .map((spec) => spec.split(':')[1]);
        let middleColor = color;
        let middleSize = size;
        if (this.propsCode[1] === '1627207' && !this.isSingleProps) {
          middleColor = size;
          middleSize = color;
        }

        const url = this.colorList.find((e) => e.label === color)?.url;
        const skuItems = {
          color: middleColor,
          size: middleSize,
          goodsSkuId: item.goodsSkuId,
          salePrice: item.skuPlatformWholePrice,
          discountPrice: item.skuDiscountPrice,
          price: Number(getPrice(item.skuPlatformWholePrice, item.skuDiscountPrice, item.skuActivityPrice)),
          activityPrice: item.skuActivityPrice,
          number: 0,
          stockNum: item.stockNum,
          url,
        };

        // 根据颜色分类
        if (skuAndPrice[`${middleColor}`]) {
          skuAndPrice[`${middleColor}`] = [
            ...skuAndPrice[`${middleColor}`],
            skuItems,
          ];
        } else {
          skuAndPrice[`${middleColor}`] = [skuItems];
        }
      });
      const skuColor = Object.keys(skuAndPrice);
      const skuInfo = Object.values(skuAndPrice);
      const tempColorList = [...this.colorList];
      skuColor.forEach((el, index) => {
        const colorItem = tempColorList.find((v) => v.label === el);
        if (colorItem) {
          colorItem.colorSkuInfo = skuInfo[index];
        }
      });
      if (this.isSingleProps) {
        this.currentSizeList = tempColorList?.map((e) => {
          return { ...e.colorSkuInfo[0] };
        }); // 设置第一次渲染时对应颜色的尺寸
      } else {
        this.currentSizeList = tempColorList?.length > 0 ? tempColorList[0]?.colorSkuInfo : []; // 设置第一次渲染时对应颜色的尺寸
      }
      this.colorList = [...tempColorList];
    } catch (err) {
      console.error(err);
    }
  };

  // 补零
  public adjustTime = (time) => {
    if (time < 10) {
      return `0${time}`;
    }
    return time;
  };

  // 获取活动时间
  @action public getActiveCountDown = (promotionPreviewVo) => {
    if (promotionPreviewVo && promotionPreviewVo.beginTime && promotionPreviewVo.endTime) {
      const beginTime = new Date(promotionPreviewVo.beginTime).getTime();
      const endTime = new Date(promotionPreviewVo.endTime).getTime();
      this.setIntervalInstance = setInterval(() => {
        const nowTime = new Date().getTime();
        const {
          days,
          hours,
          minutes,
          seconds,
          distance,
          currentActivity,
        } = getDateInterval(beginTime, endTime);
        Object.assign(this.countDownTime, {
          days: this.adjustTime(days),
          hours: this.adjustTime(hours),
          minutes: this.adjustTime(minutes),
          seconds: this.adjustTime(seconds),
          lastEndTime: distance,
          currentActivity,
        });
        if (nowTime > beginTime && nowTime < endTime) {
          this.isActive = 'end';
        } else if (nowTime < beginTime) {
          this.isActive = 'start';
        } else if (nowTime > endTime) {
          this.isActive = 'over';
          clearInterval(this.setIntervalInstance);
        }
      }, 1000);
    } else {
      this.isActive = 'init';
    }
  };

  // 改变套图
  @action public onChangePicGroup = async(picGroupId: number): Promise<void> => {
    this.currentPicGroup = picGroupId;
    window.scrollTo(0, 0);
    await this.getDetail();
  };

  // 设置显示主图
  @action public setPicAndSize = (url: string, color: string, colorCode: string): void => {
    if (url) {
      Object.assign(this, { picUrl: url });
    }
    this.colorCode = colorCode;
    this.currentSizeList = this.colorList.filter((el) => el.label === color)[0]?.colorSkuInfo;
  };

  // 数量改变
  @action public onSkuValueChange = (value: number, color: string, goodsSkuId: number, type: 'minus' | 'plus' | 'change') => {
    const skuItem = this.currentSizeList.find((el) => goodsSkuId === el.goodsSkuId);
    skuItem.number = value;
    const colorItem = this.colorList.find((el) => color === el.label);
    if (!this.isSingleProps) {
      colorItem.colorSkuInfo = [...this.currentSizeList];
    } else {
      colorItem.colorSkuInfo = [skuItem];
    }
  };

  // 加入进货车
  @action public addToCart = (): void => {
    if (this.totalNum <= 0) {
      message.warning('订购数量必须为大于0的整数');
      return;
    }
    console.log('this.colorList...', this.colorList);
    let data = [];
    this.colorList.forEach((colorItem) => {
      colorItem.colorSkuInfo?.map((sizeItem) => {
        if (sizeItem.number) {
          data = [
            ...data,
            {
              goodsId: this.goodsId,
              goodsSkuId: sizeItem.goodsSkuId,
              goodsVendorDynamicId: this.dynamicId || '',
              skuNum: sizeItem.number,
            },
          ];
        }
      });
    });
    this.addCartLoading = true;
    request({
      url: api.addToCart,
      method: 'post',
      data,
    })
      .then((res: BaseData<string>) => {
        message.success(res.data || '添加成功');
        this.resetAddData();
      })
      .catch(() => {
        message.error('添加失败');
      })
      .finally(() => {
        this.addCartLoading = false;
      });
  };

  // 重置数据
  @action public resetAddData = (): void => {
    for (let i = 0; i < this.colorList.length; i++) {
      const skuList = this.colorList[i].colorSkuInfo;
      for (let j = 0; j < skuList.length; j++) {
        const sku = skuList[j];
        sku.number = 0;
      }
    }
    if (!this.isSingleProps) {
      this.currentSizeList = this.colorList.filter((el) => el.code === this.colorCode)[0]?.colorSkuInfo;
    } else {
      this.currentSizeList.map((e) => {
        e.number = 0;
        return { ...e };
      });
    }
  };

  // 立即订购
  @action public buyNow = (): void => {
    if (this.totalNum <= 0) {
      message.warning('订购数量必须为大于0的整数');
      return;
    }

    // sku信息
    let skuVos = [];
    for (let i = 0; i < this.colorList.length; i++) {
      const skuList = this.colorList[i].colorSkuInfo;
      for (let j = 0; j < skuList.length; j++) {
        const sku = skuList[j];
        if (!sku.number) {
          continue;
        }
        skuVos = [
          ...skuVos,
          {
            goodsSkuId: sku.goodsSkuId,
            skuNum: sku.number,
            mallShoppingCartId: sku.goodsSkuId,
            price: sku.salePrice,
            color: sku.color,
            size: sku.size,
            discountPrice: sku.discountPrice,
            activityPrice: sku.activityPrice,
            totalPrice: sku.number * sku.price,
          },
        ];
      }
    }

    // 其他信息
    const orderList = {
      vendorId: this.mallShopSimpleVo.vendorId,
      vendorName: this.mallShopSimpleVo.vendorName,
      address: '',
      totalNum: this.totalNum,
      totalAmount: Number(this.totalPrice),
      lastUpdateTime: '',
      warehouseName: this.afterSale.deliverWarehouseName,
      warehouseId: null, // 立即订购按仓库分组 只有一组
      ownCustomer: this.goodsBaseInfo.ownCustomer,
      goodsVos: [
        {

          goodsId: this.goodsId,
          goodsName: this.goodsBaseInfo.goodsName,
          goodsNo: this.goodsBaseInfo.goodsNo,
          mainPicUrl: this.picUrl,
          lastUpdateTime: '',
          vendorId: this.mallShopSimpleVo.vendorId,
          vendorName: this.mallShopSimpleVo.vendorName,
          ...this.afterSale,
          skuVos,
        },
      ],
    };
    sessionStorage.setItem('confirmGoodsList', JSON.stringify([orderList]));
    sessionStorage.setItem('confirmOrigin', 'detail');
    window.open(replacePath('/egenie-ts-vogue/confirmOrder/index'));
  };

  // 一键铺货
  @action public openLaunchModal = (): void => {
    this.launchStore?.openModal(this.goodsId, this.dynamicId, 'front');
  };

  // 设置当前图片url
  @action public setPicUrl = (url: string, type: 'picture' | 'video') => {
    if (type === 'picture' && url) {
      Object.assign(this, { picUrl: url });
    }
  };

  @action public gotoMyStyle = () => {
    if (this.isErpAccount) {
      window.open(`${api.switchToErpHome}&jumpTo=myStyle`); // erp账户跳转到erp首页
    } else {
      window.open('/egenie-erp-home/shjHome?jumpTo=myStyle');
    }
  };
}
