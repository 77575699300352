
import type { FormInstance } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { observable, action, toJS } from 'mobx';
import React from 'react';
import { api, scrollToAnchor } from '../../utils';
import { getImageUrl, getImageInfo, dealUnlegalImg, CategorySelectorStore } from '../shelvesBase';
import { CategoryPropsStore, AnchorStore, MaterialStore, EditorStore, MainImgsStore, SingleUploadImgStore } from './components';
import type { TianmaoData, ICatAndDeliveryTemplates, IObj, IDeliveryTemplate } from './interface';
import { TaobaoStepOne } from './taobaoInfo/taobaoStepOne';
import TaobaoStepOneStore from './taobaoInfo/taobaoStepOne/store';
import { TaobaoStepTwo } from './taobaoInfo/taobaoStepTwo';
import { TaobaoStore } from './taobaoInfo/taobaoStepTwo/store';
import { TmallStepOne } from './tianmaoInfo/tmallStepOne';
import TmallStepOneStore from './tianmaoInfo/tmallStepOne/store';
import TianmaoInfo from './tianmaoInfo/tmallStepTwo';
import BaseinfoStore from './tianmaoInfo/tmallStepTwo/baseinfo/store';
import GenerateDurabilityInfoStore from './tianmaoInfo/tmallStepTwo/generateDurabilityInfo/store';
import GenerateTagsInfoStore from './tianmaoInfo/tmallStepTwo/generateTagsInfo/store';
import TianmaoStore from './tianmaoInfo/tmallStepTwo/store';
import TableStore from './tianmaoInfo/tmallStepTwo/tableInfo/store';

class Store {
  constructor() {
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    this.gmsGoodsId = params.get('gmsGoodsId');
    this.shopId = params.get('shopId');
    this.platformType = Number(params.get('platformType'));
    this.pageId = params.get('pageId');

    this.getPosGoodsGroupPics();// 款式图
    this.getTaobaDeliveryTemplates();// 运费模版
    if (this.platformType === -100) {
      this.tmallStepOneStore.categoryStore.initData(this.gmsGoodsId, this.platformType, this.shopId);
      this.getAllWashIcons();
      this.getAllSizeTpl();
      this.getProvinceList();

      // this.getAllShops();// 天猫使用？
    } else if (this.platformType === 1) {
      this.getShopCatogory(this.shopId);// 店铺分类
      this.taobaoStepOneStore.categoryStore.initData(this.gmsGoodsId, this.platformType, this.shopId);
    }
  }

  @observable public parent;

  @observable public pageId;

  @observable public callback;

  @observable public loading = false;

  @observable public visibleSubmitResult = false;

  @observable public detailId = '';

  @observable public shopName = '';

  @observable public platformType;// 平台类型 -100为天猫

  @observable public taobaoStore = new TaobaoStore(this);

  @observable public tianmaoStore = new TianmaoStore(this);

  @observable public generateTagsInfoStore = new GenerateTagsInfoStore(this);

  @observable public generateDurabilityInfoStore = new GenerateDurabilityInfoStore(this);

  @observable public tableStore = new TableStore(this);

  @observable public taobaoStepOneStore = new TaobaoStepOneStore(this);

  @observable public tmallStepOneStore = new TmallStepOneStore(this);

  @observable public materialStore = new MaterialStore(this);// 材质成分

  @observable public categoryPropsStore = new CategoryPropsStore(this);// 商品属性

  @observable public editorStore = new EditorStore(this);// 编辑器

  @observable public mainImgsStore = new MainImgsStore(this);// 商品主图

  @observable public baseinfoStore = new BaseinfoStore(this);

  @observable public anchorStore = new AnchorStore(this);// 右侧锚点菜单栏

  @observable public singleUploadImgStore = new SingleUploadImgStore(this);

  @observable public taobaoFormRef = React.createRef<FormInstance>();

  @observable public tianmaoFormRef = React.createRef<FormInstance>();

  @observable public shopId: string;

  @observable public gmsGoodsId: string;

  @observable public currentStep = 0;

  @observable public goodsPropsValuesTmp: IObj;

  @observable public listingTimeMode = '0'; // 上架时间 0 ： 立即上架 2: 放入仓库

  @observable public vendorName = ''; // 档口号

  @observable public categoryId = 0;// 选中类目id

  @observable public TAOBAO_STEPS = [
    {
      title: '选择类目/品牌',
      content: (
        <TaobaoStepOne taobaoStepOneStore={this.taobaoStepOneStore}/>
      ),
    },
    {
      title: '完善商品信息',
      content: (
        <TaobaoStepTwo
          anchorStore={this.anchorStore}
          editorStore={this.editorStore}
          mainImgsStore={this.mainImgsStore}
          materialStore={this.materialStore}
          singleUploadImgStore={this.singleUploadImgStore}
          taobaoStore={this.taobaoStore}
        />
      ),
    },
  ];

  @observable public TIANMAO_STEPS = [
    {
      title: '选择类目/品牌',
      content: (
        <TmallStepOne
          categoryPropsStore={this.categoryPropsStore}
          materialStore={this.materialStore}
          tmallStepOneStore={this.tmallStepOneStore}
        />
      ),
    },
    {
      title: '完善商品信息',
      content: (
        <TianmaoInfo
          anchorStore={this.anchorStore}
          baseinfoStore={this.baseinfoStore}
          categoryPropsStore={this.categoryPropsStore}
          editorStore={this.editorStore}
          generateDurabilityInfoStore={this.generateDurabilityInfoStore}
          generateTagsInfoStore={this.generateTagsInfoStore}
          mainImgsStore={this.mainImgsStore}
          materialStore={this.materialStore}
          singleUploadImgStore={this.singleUploadImgStore}
          store={this}
          tableStore={this.tableStore}
          tianmaoStore={this.tianmaoStore}
          tmallStepOneStore={this.tmallStepOneStore}
        />
      ),
    },
  ];

  @observable public visible = false;

  @observable public visiblePermission = false;

  @observable public authBtnLoading = false;

  @observable public submitResult = 'init';

  @observable public errorMessage = '';

  @action public gotoAuth = () => {
    this.authBtnLoading = true;
    request<BaseData<string>>({ url: `${api.gotoAuth}/${this.shopId}` }).then((res) => {
      this.authBtnLoading = false;
      window.open(res.data);
      this.visiblePermission = false;
    })
      .catch(() => this.authBtnLoading = false);
  };

  @action public getTianmaoInfo = () => {
    this.loading = true;
    request<BaseData<TianmaoData>>({
      url: api.getGoodsPutOnShelfDetail,
      method: 'post',
      data: { gmsGoodsId: Number(this.gmsGoodsId) },
    }).then(async(res) => {
      const {
        goodsProps,
        gmsGoodsTmallExtendsVo,
        linkedPropsMap,
        vendorSimpleVo,
        saleProps,
        goodsSkuVos,
        goodsPutOnShelfTmallBaseVo,
        goodsPropPicVos,
        goodsPicVo,
        sellerCids,
        customSizeSupport,
        sizeOptionList,
      } = res.data;
      const {
        attach51 = '', // 吊牌
        attach52 = '', // 耐久
        attach55 = '', // 质检
        attach56 = '', // 合格证
        modelTryOnSizeList = {}, // 模特试穿信息 图和表
        modelTryOnPic = '',
        provinceCode = '',
        province = '',
        cityCode = '',
        city = '',
        sellingPoint = '', // 卖点
        shortTitle = '', // 短标题
      } = gmsGoodsTmallExtendsVo || {};
      this.tmallStepOneStore.cascadeProps = {
        ...this.tmallStepOneStore.cascadeProps,
        ...(linkedPropsMap || {}),
      };
      this.tmallStepOneStore.goodsProps = this.getLastCategoryProps(goodsProps || []);
      const requiredProps = this.tmallStepOneStore.goodsProps.filter((el) => el.required);
      this.anchorStore.baseinfoDyamicProps = requiredProps;
      const allTitles = this.anchorStore.allTitles;
      allTitles.baseinfo.requiredNum = 3;
      allTitles.baseinfo.requiredNum += requiredProps.length;
      this.anchorStore.allTitles = allTitles;

      this.vendorName = vendorSimpleVo?.vendorNo || '';// 档口号

      const { goodsNo, salePrice, stockNum, goodsCode, goodsName } = goodsPutOnShelfTmallBaseVo;

      // 第二步也有可能返回材质成分
      if (res.data.productMaterialPropsVo) {
        this.baseinfoStore.hasMaterialProps = true;
        Object.assign(this.materialStore, {
          materialDict: res.data.productMaterialPropsVo?.materialPropOptions || [],
          materialDictNoInput: res.data.productMaterialPropsVo.noNeedContentPropNames || [],
          isMaterialRequired: res.data.productMaterialPropsVo.required,
        });
        const materialPropValus = this.tmallStepOneStore.getPropsNeedInput(res.data.productMaterialPropsVo?.materialPropValus || [], res.data.productMaterialPropsVo.noNeedContentPropNames || []);
        this.materialStore.writebackMaterialProps(materialPropValus);

        // 材质成分必填的话+1
        if (res.data.productMaterialPropsVo.required) {
          this.anchorStore.allTitles.baseinfo.requiredNum++;
        }
      }

      // 有分类才回写
      if (sellerCids) {
        const { checkOptions } = sellerCids;

        this.baseinfoStore.classificationList = this.mapOptions(checkOptions, 'value', 'key');
      }

      // 处理颜色图片格式
      try {
        for (let i = 0; i < goodsPropPicVos.length; i++) {
          const { Format } = await getImageInfo(goodsPropPicVos[i].url);
          goodsPropPicVos[i].url = dealUnlegalImg(goodsPropPicVos[i].url, Format.value);
        }
      } catch (e) {
        console.log('处理颜色图片出错', e);
      }

      // 回写颜色尺码商品规格
      this.tianmaoStore.sizeColorStore.initData(saleProps, goodsPropPicVos, goodsSkuVos, customSizeSupport, sizeOptionList);

      // 回写销售信息的一口价和数量
      this.tianmaoStore.saleInfoRef.current?.setFieldsValue({ goodsCode });

      try {
        // 处理主图尺寸
        for (let i = 0; i < goodsPicVo.mainPics.length; i++) {
          goodsPicVo.mainPics[i] = await this.dealMainImg(goodsPicVo.mainPics[i]);
        }
      } catch (e) {
        console.log('加载主图错误', e);
      }

      // 回写主图
      this.mainImgsStore.writeBackMainImgs(goodsPicVo);

      // 回写富文本
      this.editorStore.writeBackEditor(this.gmsGoodsId);

      // 回写资质图片
      this.tianmaoStore.writeBackGoodsResourcePicVoList({
        attach51,
        attach52,
        attach55,
        attach56,
      });

      // 回写模特试穿信息
      this.tableStore.writeBackModelTryOnVo(modelTryOnSizeList, modelTryOnPic);

      // 回写地址信息
      this.tianmaoStore.writeBackAddress(provinceCode, cityCode, province, city);

      this.generateTagsInfoStore.writeBackTagsInfo(this.categoryPropsStore.brandName, this.categoryPropsStore.goodsId, this.categoryId);
      this.tianmaoStore.writeBackOtherInfo(res.data);

      // sku信息（sku表格）
      this.loading = false;
    })
      .catch((e) => {
        console.log(e);
        this.loading = false;
      });
  };

  @action public getLastCategoryProps = (categoryProps): any => {
    if (!categoryProps || categoryProps.length === 0) {
      return [];
    }
    const _categoryProps = categoryProps;
    const cascadeProps = this.tmallStepOneStore.cascadeProps;
    const cascadePropsKeys = Object.keys(cascadeProps);
    const goodsPropsValues = {};
    categoryProps.forEach((item, index) => {
      let _index = index;
      const isCascade = cascadePropsKeys.find((el) => el === `${item.id}_${item.value}`);
      if (isCascade) {
        _index++;
        _categoryProps.splice(_index, 0, cascadeProps[`${item.id}_${item.value}`]);
        goodsPropsValues[item.id] = {
          preV: '',
          currentV: `${item.value}`,
        };
      }
    });

    this.goodsPropsValuesTmp = goodsPropsValues;
    return _categoryProps;
  };

  // 获取水洗图标
  @action public getAllWashIcons = () => {
    request<BaseData<any>>({
      url: api.getAllWashIcons,
      method: 'get',
    }).then((res) => {
      this.generateDurabilityInfoStore.allWashIcons = res.data || [];
      const checkedIds = this.generateDurabilityInfoStore.checkedIds;
      this.generateDurabilityInfoStore.checkList = this.generateDurabilityInfoStore.allWashIcons.filter((item) => checkedIds.filter((el) => el === item.id).length > 0);
    });
  };

  // 获取省
  @action public getProvinceList = (): void => {
    request<BaseData<any[]>>({
      url: api.getProvinceList,
      method: 'get',
    }).then((res) => {
      this.tianmaoStore.provinceList = this.mapOptions(res?.data || [], 'provinceName', 'id');
    })
      .catch(() => {
        this.tianmaoStore.provinceList = [];
        this.tianmaoStore.cityList = [];
      });
  };

  // 获取市
  @action public getCityList = (parent_id) => {
    return request<BaseData<Array<{ id?: number;cityName?: string; }>>>({
      url: `${api.getCityList}`,
      method: 'get',
      params: { provinceId: parent_id },
    }).then((res) => {
      this.tianmaoStore.cityList = this.mapOptions(res?.data || [], 'cityName', 'id');
      this.tianmaoStore.city = '';
      this.tianmaoStore.cityId = '';
      return Promise.resolve(res);
    })
      .catch(() => {
        this.tianmaoStore.cityList = [];
      });
  };

  // 获取模板列表
  @action public getAllSizeTpl = (): void => {
    request<BaseData<any>>({
      url: api.getSizeTpl,
      method: 'post',
      data: { shopId: this.shopId },
    }).then((res) => {
      this.tianmaoStore.sizeMap = res.data || [];
    });
  };

  // 获取运费模版
  @action public getTaobaDeliveryTemplates = (): Promise<void> => {
    return request<BaseData<IDeliveryTemplate[]>>({
      url: api.getTaobaoDeliveryTemplates,
      method: 'post',
      data: { shopId: this.shopId },
    }).then((res) => {
      const deliveryOptions = res.data?.map((item) => ({
        label: item.name,
        value: String(item.templateId),
      }));
      if (this.platformType !== -100) {
        this.taobaoStore.deliveryTemplatesOptions = deliveryOptions;
        return;
      }
      this.tianmaoStore.freightTemplateList = deliveryOptions;
    });
  };

  // 获取款式库图片
  @action public getPosGoodsGroupPics = (): void => {
    request<BaseData<any>>({
      url: api.getPosGoodsGroupPics,
      method: 'post',
      data: { gmsGoodsId: this.gmsGoodsId },
    }).then(async(res) => {
      try {
        this.mainImgsStore.styleImgLists = this.dealMainPics(res?.data || []);

        // 处理款式库中的图片尺寸
        for (let i = 0; i < this.mainImgsStore.styleImgLists.length; i++) {
          this.mainImgsStore.styleImgLists[i] = await this.dealMainImg(this.mainImgsStore.styleImgLists[i]);
        }
      } catch (e) {
        console.log('获取款式库图片出错', e);
      }
    })
      .catch(() => {
        this.mainImgsStore.styleImgLists = [];
      });
  };

  @action public dealMainPics = (mainPicUrls): any => {
    if (mainPicUrls.length === 0) {
      return [];
    }
    let arr = [];
    mainPicUrls.forEach((item) => {
      arr = [
        ...arr,
        ...item.mainPicUrls,
      ];
    });
    return arr;
  };

  @action public next = (): void => {
    this.currentStep += 1;
    scrollToAnchor('stepHeader');
  };

  @action public submitAgain = (): void => {
    this.currentStep = 0;
    scrollToAnchor('stepHeader');
  };

  private mapOptions = (arr: any[], label, value) => {
    return arr.map((item) => ({
      label: item[label],
      value: String(item[value]),
    }));
  };

  @action public closeModal = () => {
    window.top.egenie.closeTab(this.pageId);
  };

  @action public closeResultModal = () => {
    this.visibleSubmitResult = false;
  };

  // 店铺分类
  @action public getShopCatogory = (shopId) => {
    request<BaseData<[]>>({
      url: api.getShopCatogoryTaobao,
      method: 'POST',
      data: { shopId },
    }).then((res) => {
      const options = this.mapOptions(res.data, 'name', 'cid');
      this.taobaoStore.shopCategoryOptions = options;
      this.baseinfoStore.classificationList = options;
    });
  };

  // 处理主图尺寸（天猫 淘宝公用）
  public dealMainImg = async(url) => {
    const { ImageWidth, ImageHeight, Format } = await getImageInfo(url);

    // 处理主图（比例1:1）
    const longer = Math.max(Number(ImageWidth.value), Number(ImageHeight.value));
    const resizeWidth = longer;
    const resizeHeight = longer;

    // 处理尺寸
    let newUrl = getImageUrl(url, resizeWidth, resizeHeight, ImageWidth, ImageHeight);

    // 处理格式
    newUrl = dealUnlegalImg(newUrl, Format.value);
    return newUrl;
  };

  // 处理长图尺寸（天猫 淘宝公用）
  public dealLongImg = async(url) => {
    const { ImageWidth, ImageHeight, Format } = await getImageInfo(url);

    // 800*1200
    let newUrl = getImageUrl(url, 800, 1200, ImageWidth, ImageHeight);

    // 处理格式
    newUrl = dealUnlegalImg(newUrl, Format.value);

    return newUrl;
  };

  // 处理透明图尺寸（天猫用）
  public dealTransImg = async(url) => {
    const { ImageWidth, ImageHeight, Format } = await getImageInfo(url);

    // 800*800
    let newUrl = getImageUrl(url, 800, 800, ImageWidth, ImageHeight);

    // 处理格式
    newUrl = dealUnlegalImg(newUrl, Format.value);

    return newUrl;
  };
}

export default Store;
