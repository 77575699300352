import type { FormInstance } from 'antd';
import { message, Modal } from 'antd';
import type { BaseData, IObj } from 'egenie-utils';
import { request } from 'egenie-utils';
import { cloneDeep } from 'lodash';
import { action, computed, observable, toJS } from 'mobx';
import { api, scrollToAnchor } from '../../utils';
import PasswordModel from '../cashier/widget/passwordModal/model';
import { WorryFreePurchaseStore } from '../components/worryFreePurchase/store';
import type { Address, City, District, Option, Origin, ParsingAddress, Post, PostList, Province, PutAwayList, TradeOrder, VendorAddress } from './interface';
import { EditAddressStore } from './modal/editAddress/store';
import { SetCourierStore } from './modal/setCourier/store';

export default class OrderCheckStore {
  constructor() {
    this.init();
  }

  @observable public parent;

  @observable public editAddressStore = new EditAddressStore({ parent: this });

  @observable public setCourierStore = new SetCourierStore({ parent: this });

  @observable public resultVisible = false; // 支付反馈结果弹窗

  @observable private originType: Origin = 'cart';

  @observable public goodsVendorDynamicId = null; // 档口动态id

  @observable public deliveryType = 0; // 0： 物流配送 1：档口自提

  @observable public mallReceiverAddressId: number = null; // 当前选中地址

  @observable public addressTabKey = '1'; // 当前地址是常用地址还是新建地址； 1常用地址， 2新建地址

  @observable public currentAddress = null;

  @observable public addressList: Address[] = []; // 展示地址

  @observable public allAddressList: Address[] = []; // 所有地址列表

  @observable public showMoreAddress = false;

  @observable public goodsListByVendor: PutAwayList[] = [];// 商品列表

  @observable public goodsListByWareHouse: PutAwayList[] = [];

  @observable public worryFreeGoodsList = []; // 无忧退货列表

  @observable public address = ''; // 智能解析的地址字符串

  @observable public remark = ''; // 备注

  @observable public fright = 0; // 运费

  @observable public totalNumber = 0; // 总计商品件数

  @observable public totalAmount = 0; // 总计金额

  @observable public passwordModel = new PasswordModel(this);

  @observable public newAddRef: FormInstance;

  @observable public addressOptions: Option[] = []; // 地址option

  @observable public parsingLoading = false; // 地址解析loading

  @observable public bottomRef: FormInstance | undefined;

  @observable public remarkLength = 0; // 输入备注字数

  @observable public showFreeWorryEntrance = false; // 是否展示无忧退货入口

  @observable public selectedWorryFreeList = []; // 已选择无忧退货列表

  @observable public postList = []; // 运费列表

  @observable public successVisible = false; // 档口自提成功弹窗

  @observable public submitLoading = false; // 提交按钮的loading

  @observable public hasPostFee = false; // 是否有运费

  @observable public wmsToCourier = {}; // 网仓对应的默认快递

  @observable public perBillingProjectCosts = [];// 零售商计费列表

  @observable public totalPerBillingProjectCosts = 0;// 零售商计费合计

  @observable public postAmountDiscount = { // 首单免运费
    hasCoupon: false,
    discountAmount: 0, // 免运费金额
  };

  @computed
  public get worryFreeAmount() {
    return this.selectedWorryFreeList.reduce((pre, cur) => {
      return pre + (cur.goodsNum * Number(cur?.worryFreeCost || 0));
    }, 0);
  }

  @computed
  public get receiverInfo() {
    if (!this.currentAddress) {
      return '收货人信息不全，请补全信息';
    } else {
      const {
        receiverName,
        receiverMobile,
        province,
        city,
        district,
        receiverAddress,
      } = this.currentAddress;
      if ((!province || province.length === 0) || (!city || city.length === 0) || (!district || district.length === 0)) {
        return '收货人所在地区信息不全，请补全信息';
      }
      const infoList = [
        receiverName,
        receiverMobile,
        province,
        city,
        district,
        receiverAddress,
      ];
      const result = infoList.some((el) => !el);
      if (result) {
        return '收货人信息不全，请补全信息';
      } else {
        return `${receiverName || ''}  ${receiverMobile || ''}  ${province || ''}${city || ''}${district || ''}${receiverAddress || ''}`;
      }
    }
  }

  // 是否是存量客户， 存量客户展示档口自提
  @computed
  public get ownerCustomer() {
    const result = this.goodsListByVendor.every((el) => el.ownCustomer);
    return result;
  }

  private init = async(): Promise<void> => {
    this.getConfirmGoodsList(); // 获取下单的商品
    await this.getAddress(); // 获取保存的全部地址
    await this.getDefaultAddress(); // 获取默认地址
    this.getVendorAddress(); // 根据商品获取档口地址
    this.getWorryFreeGoods(); // 获取无忧退货的商品
    this.queryProvince(); // 请求新建联系人的省份
    await this.queryPostAmountDiscount();
    await this.getPostFee(); // 请求运费
  };

  @action private setSelectedWorryFreeList = (goodsList): void => {
    this.selectedWorryFreeList = goodsList;
  };

  // 计算每个档口下商品总件数和总金额
  @action private calcTotalAmountByVendor = () => {
    let totalNumber = 0;
    let totalAmount = 0;
    this.goodsListByVendor?.forEach((vendor) => {
      vendor.totalNum = 0;
      vendor.totalAmount = 0;
      vendor.goodsVos.forEach((goods) => {
        vendor.totalNum += goods.skuVos.reduce((num, sku) => {
          return num + sku.skuNum;
        }, 0);
        vendor.totalAmount += goods.skuVos.reduce((price, sku) => {
          return price + Number(sku.totalPrice);
        }, 0);
        if (this.originType === 'cart') { // 进货车下单需要拆分尺码和颜色
          goods.skuVos.forEach((sku) => {
            const [
              color,
              size,
            ] = sku.goodsSpec.split(';')
              .map((spec) => spec.split(':')[1]);
            sku.color = color;
            sku.size = size;
          });
        }
      });
      totalAmount += vendor.totalAmount;
      totalNumber += vendor.totalNum;
    });
    this.totalAmount = totalAmount;
    this.totalNumber = totalNumber;
    this.goodsListByVendor = toJS(this.goodsListByVendor);
  };

  // 关闭支付结果反馈弹窗
  public onCloseResult = (): void => {
    this.resultVisible = false;
    this.deliveryType = 0;
  };

  // 获取档口地址
  @action public getVendorAddress = async(): Promise<void> => {
    const vendorIds = this.goodsListByVendor.map((item) => item.vendorId);
    const res: BaseData<VendorAddress[]> = await request({ url: `${api.getVendorAddress}?vendorIds=${vendorIds.join(',')}` });
    this.goodsListByVendor.forEach((vendor) => {
      const vendorInfo = res.data.find((item) => item.vendorId === vendor.vendorId);
      vendor.address = vendorInfo.address;
    });
  };

  // 改变提货方式
  @action public onChangeDeliveryType = (deliveryType: number): void => {
    this.deliveryType = deliveryType;
  };

  // 获取默认地址
  @action public getDefaultAddress = async(): Promise<void> => {
    const res: BaseData<Address> = await request({ url: api.queryMallDefaultAddress });
    if (!res.data) {
      return;
    }
    this.addressList = [res.data];
    if (!this.mallReceiverAddressId) {
      this.mallReceiverAddressId = res.data.id;
      this.currentAddress = res.data;
    }
  };

  // 获取所有地址
  @action public getAddress = async(): Promise<void> => {
    const res: BaseData<Address[]> = await request({ url: api.queryAddressList });
    this.allAddressList = res.data;
  };

  // 设为当前地址
  @action public setAddress = (address: Address): void => {
    this.mallReceiverAddressId = address.id;
    this.currentAddress = address;
    this.addressList = [address];
    this.showMoreAddress = false;

    // 地址改变后重新请求运费
    this.getPostFee();
  };

  // 设为默认地址
  @action public setDefaultAddress = async(addressId: number): Promise<void> => {
    await request({
      url: api.updateDefaultAddress,
      method: 'post',
      data: {
        mallReceiverAddressId: addressId,
        defaultAddress: 1,
      },
    });
    message.success('设置成功');
    this.setAddressAfterDefault(addressId);
  };

  // 设为默认地址后的判断
  @action public setAddressAfterDefault = async(addressId: number): Promise<void> => {
    if (this.showMoreAddress) {
      await this.getAddress();
      const address = this.allAddressList.find((item) => item.id === addressId);
      this.addressList = [address];
      this.mallReceiverAddressId = address.id;
      this.currentAddress = address;
      this.showMoreAddress = false;
    } else {
      this.getDefaultAddress();
    }
  };

  // 删除地址
  @action public delAddress = (addressId: number): void => {
    Modal.confirm({
      title: '确认删除当前收货地址吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: async() => {
        await request({
          url: api.deleteAddress,
          method: 'post',
          data: { ids: String(addressId) },
        });
        message.success('删除成功');
        await this.getAddress();
        this.addressList = this.allAddressList;
      },
    });
  };

  // 收起/展开更多地址
  @action public onToggleAddress = (): void => {
    if (this.showMoreAddress) { // 现在是展开状态
      this.addressList = [this.currentAddress];
    } else {
      this.addressList = this.allAddressList;
    }
    this.showMoreAddress = !this.showMoreAddress;
  };

  @action public onChangeRemark = (e): void => {
    this.remark = e.target.value;
  };

  // 校验提交信息
  public validateInfo = async(): Promise<void> => {
    if (this.deliveryType === 0 && this.addressTabKey === '1' && !this.mallReceiverAddressId) {
      message.warning('请选择收货地址');
      return;
    }

    // 如果是物流配送，并且是新建地址
    if (this.deliveryType === 0 && this.addressTabKey === '2') {
      try {
        await this.newAddRef?.validateFields();
      } catch (err) {
        this.backTop();
        return;
      }
    }

    // 校验是否填写运费
    if (this.deliveryType === 0 && this.hasPostFee) {
      try {
        await this.bottomRef?.validateFields();
      } catch (err) {
        return;
      }
    }

    this.createOrder();
  };

  // 生成订单
  public createOrder = async(): Promise<void> => {
    this.submitLoading = true;
    try {
      if (this.originType === 'cart') {
        await this.createOrderByCart();
      } else {
        await this.createOrderByDetail();
      }
    } catch (err) {
      this.submitLoading = false;
    }
    this.submitLoading = false;
  };

  // 获取需要提交的无忧退货数据
  private getWorryFreeList = (): Array<{
    goodsId: number;
    returnNumber: number;
  }> => {
    return this.selectedWorryFreeList.map((el) => {
      return {
        goodsId: el.goodsId,
        returnNumber: el.goodsNum,
      };
    }) || [];
  };

  // 处理地址
  private handleAddress = (address: string[]) => {
    if (!address) {
      return {};
    }
    try {
      const province = this.addressOptions?.find((el) => el.value === address[0]) || {} as Option;
      const city = province?.children?.find((el) => el.value === address[1]) || {} as Option;
      const district = city?.children?.find((el) => el.value === address[2]);
      return {
        province: province?.label,
        provinceId: province?.value,
        city: city?.label,
        cityId: city?.value,
        district: district?.label,
        districtId: district?.value,
      };
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  // 根据type整理快递参数
  private courierParams = (type: string): Array<{ cpCode: number; courierId: number; cloudWmsId?: number; shopId?: number; }> => {
    const arr = [];
    Object.keys(this.wmsToCourier)
      .map((key) => {
        if (this.wmsToCourier?.[key].type === type) {
          const item = this.wmsToCourier?.[key]?.postList?.find((v) => v.courierId === this.wmsToCourier?.[key]?.currentCourierId);
          const cloudOrShopId = type === 'cloud' ? 'cloudWmsId' : 'shopId';
          arr.push({
            [cloudOrShopId]: key,
            cpCode: item?.cpCode,
            courierId: item?.courierId,
          });
        }
      });
    return arr;
  };

  // 进货车生成订单
  private createOrderByCart = async(): Promise<void> => {
    let mallShoppingCartIds = [];
    this.goodsListByVendor.forEach((vendor) => {
      vendor.goodsVos.forEach((goods) => {
        mallShoppingCartIds = mallShoppingCartIds.concat(goods.skuVos.map((sku) => sku.mallShoppingCartId));
      });
    });

    // 无忧退货信息
    const mallReturnPolicyToList = this.getWorryFreeList();
    const params = {
      clientType: 1,
      mallReturnPolicyToList,
      deliveryType: this.deliveryType,
      mallShoppingCartIds: mallShoppingCartIds.join(','),
      message: this.remark,
    };

    // 地址信息
    if (this.deliveryType === 0) {
      const { remark } = this.bottomRef?.getFieldsValue();
      Object.assign(params, {
        courierParams: this.courierParams('cloud'),
        shopCourierParam: this.courierParams('vendor'),
        message: remark,
      });

      if (this.addressTabKey === '2') {
        const {
          name,
          phone,
          address,
          detail,
          setDefault,
        } = this.newAddRef?.getFieldsValue();
        if (!address || address.length !== 3 || address.some((e) => e.length === 0)) {
          message.warning('收货人所在地区信息不全，请补全信息');
          return;
        }
        if (setDefault) {
          const res: BaseData<string> = await this.createCommonAddress();
          Object.assign(params, { mallReceiverAddressId: res?.data });
        } else {
          const addressParam = {};
          const addressInfo = this.handleAddress(address);
          Object.assign(addressParam, {
            ...addressInfo,
            receiverName: name,
            receiverAddress: detail,
            receiverMobile: phone,
          });
          Object.assign(params, { addressParam });
        }
      }

      if (this.addressTabKey === '1') {
        Object.assign(params, { mallReceiverAddressId: this.mallReceiverAddressId });
      }
    } else {
      delete params.mallReturnPolicyToList;
    }
    const res: BaseData<TradeOrder[]> = await request({
      url: api.createOrderByCart,
      method: 'post',
      data: params,
    });
    this.toPay(res.data);
  };

  // 商品详情生成订单
  private createOrderByDetail = async(): Promise<void> => {
    const skuParams = [];
    this.goodsListByVendor.forEach((vendor) => {
      vendor.goodsVos.forEach((goods) => {
        goods.skuVos.forEach((sku) => {
          skuParams.push({
            goodsSkuId: sku.mallShoppingCartId,
            skuNum: sku.skuNum,
          });
        });
      });
    });

    // 无忧退货信息
    const mallReturnPolicyToList = this.getWorryFreeList();
    const params = {
      clientType: 1,
      mallReturnPolicyToList,
      deliveryType: this.deliveryType,
      mallReceiverAddressId: this.mallReceiverAddressId,
      skuParams,
      message: this.remark,
      goodsVendorDynamicId: this.goodsVendorDynamicId,
      sharerId: '', // 分享人id
      courierParams: this.courierParams('cloud'),
      shopCourierParam: this.courierParams('vendor'),
    };

    // 地址信息,deliveryType为物流配送
    if (this.deliveryType === 0) {
      const {
        postFee,
        remark,
      } = this.bottomRef?.getFieldsValue();

      // 快递公司信息
      if (this.hasPostFee) {
        const post = this.postList?.find((el) => el.value === postFee);
        Object.assign(params, { message: remark });
      }
      if (this.addressTabKey === '1') {
        Object.assign(params, { mallReceiverAddressId: this.mallReceiverAddressId });
      } else {
        const {
          name,
          phone,
          address,
          detail,
          setDefault,
        } = this.newAddRef?.getFieldsValue();
        if (setDefault) {
          const res: BaseData<string> = await this.createCommonAddress();
          Object.assign(params, { mallReceiverAddressId: res?.data });
        } else {
          const addressParam = {};
          const addressInfo = this.handleAddress(address);
          Object.assign(addressParam, {
            ...addressInfo,
            receiverName: name,
            receiverAddress: detail,
            receiverMobile: phone,
          });
          Object.assign(params, {
            addressParam,
            mallReceiverAddressId: undefined, // 新建地址清空所选地址id
          });
        }
      }
    } else {
      delete params.mallReturnPolicyToList;
    }

    try {
      const res: BaseData<TradeOrder[]> = await request({
        url: api.createOrderBySku,
        method: 'post',
        data: params,
      });
      this.toPay(res.data);
    } catch (err) {
      message.error('提交失败，请联系管理员或重试');
    }
  };

  @action private toPay = (list: TradeOrder[]): void => {
    if (this.deliveryType === 0) {
      const tradeOrderIdList = list.map((item) => item.tradeOrderId);
      const carelessIdList = list.map((item) => item.carelessTradeOrderId);
      const result = tradeOrderIdList.concat(carelessIdList)
        .filter((item) => item);
      window.open(`/egenie-ts-vogue/cashier/index?tradeOrderIds=${JSON.stringify(result)}`, '收银台');
      window.close();
    } else { // 自提单
      this.successVisible = true;

      // window.close();
    }
  };

  // 打开编辑弹窗
  public openEditModal = (id: number) => {
    this.editAddressStore.onShow(id);
  };

  // 设置ref
  @action public setNewAddRef = (ref: FormInstance): void => {
    this.newAddRef = ref;
  };

  // 地址字符串改变
  @action public onAddressChange = (e) => {
    this.address = e.target.value;
  };

  // 地址解析请求
  @action public parsingAddress = async(): Promise<void> => {
    if (!this.address) {
      return;
    }
    this.parsingLoading = true;
    try {
      const res: BaseData<ParsingAddress> = await request({
        url: api.parsingAddress,
        method: 'post',
        data: { address: this.address },
      });
      this.parsingLoading = false;
      const {
        provinceId,
        cityId,
        districtId,
        province,
        city,
        district,
        detail,
        phone,
        name,
      } = res?.data;

      // if (province_id && city_id && district_id) {
      await this.queryDetailAddress(provinceId, cityId);
      this.newAddRef.setFieldsValue({
        name,
        phone,
        address: [
          provinceId,
          cityId,
          districtId,
        ],
        detail,
      });

      // 解析成功后回显到去结算区域;
      this.currentAddress = {
        province,
        provinceId,
        city,
        cityId,
        district,
        districtId,
        receiverName: name,
        receiverAddress: detail,
        receiverMobile: phone,
      };

      this.getPostFee(); // 解析后获取运费
      // } else {
      //   message.info('解析失败');
      // }
    } catch (err) {
      this.parsingLoading = false;
    }
  };

  // 请求具体省市区
  @action private queryDetailAddress = async(provinceId: string, cityId: string): Promise<void> => {
    // 请求具体地址
    const districtList = await this.queryDistrict(cityId);
    const cityList = await this.queryCity(provinceId);
    const targetCity = cityList.find((el) => el.value === `${cityId}`);
    targetCity.children = districtList;
    await this.queryProvince();
    const targetProvince = this.addressOptions.find((el) => el.value === `${provinceId}`);
    targetProvince.children = cityList;
    this.addressOptions = [...this.addressOptions];
  };

  // 请求省份
  @action public queryProvince = async(): Promise<void> => {
    const res: BaseData<Province[]> = await request({ url: api.queryProvince });
    this.addressOptions = res?.data?.map((el) => {
      return {
        label: el.provinceName,
        value: `${el.id}`,
        isLeaf: false,
      };
    });
  };

  // 请求城市
  @action public queryCity = async(provinceId: string | number): Promise<Option[]> => {
    const res: BaseData<City[]> = await request({ url: `${api.queryCity}?provinceId=${provinceId}` });
    const cityList = res?.data?.map((el) => {
      return {
        label: el.cityName,
        value: `${el.id}`,
        isLeaf: false,
      };
    });
    return Promise.resolve(cityList);
  };

  // 请求地区
  @action public queryDistrict = async(cityId: string | number): Promise<Option[]> => {
    const res: BaseData<District[]> = await request({ url: `${api.queryDistrict}?cityId=${cityId}` });
    const districtList = res?.data?.map((el) => {
      return {
        label: el.districtName,
        value: `${el.id}`,
        isLeaf: true,
      };
    });
    return Promise.resolve(districtList);
  };

  // 动态加载地区
  public loadData = async(selectedOptions: Option[]): Promise<void> => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    if (selectedOptions.length === 1) {
      const city = await this.queryCity(targetOption.value);
      const current = this.addressOptions.find((e) => e.value === targetOption.value);
      current.children = city;
      current.loading = false;
      this.addressOptions = [...this.addressOptions];
    } else if (selectedOptions.length === 2) {
      const district = await this.queryDistrict(targetOption.value);
      const selectedProvince = selectedOptions[0];
      const cityList = this.addressOptions.find((e) => e.value === selectedProvince.value).children;
      console.log('targetOption....', cityList);
      const current = cityList.find((e) => e.value === targetOption.value);
      current.children = district;
      current.loading = false;
      this.addressOptions = [...this.addressOptions];
    }
  };

  // 打开无忧退货选择
  public openWorryFreeModal = (): void => {
    this.worryFreePurchaseStore.onShow(this.worryFreeGoodsList || [], this.selectedWorryFreeList || []);
  };

  // 设置ref
  @action public setBottomRef = (ref: FormInstance): void => {
    this.bottomRef = ref;
  };

  // 备注字段改变
  @action public onBottomFormChange = (values: Record<'remark' | 'postFee', string>): void => {
    if (Object.prototype.hasOwnProperty.call(values, 'remark')) {
      this.remarkLength = values.remark?.length || 0;
    }
  };

  // 从storage中或取参数
  @action private getConfirmGoodsList = (): void => {
    // 获取传递参数
    this.originType = sessionStorage.getItem('confirmOrigin') as Origin;

    // 获取商品列表
    const confirmGoodsList = sessionStorage.getItem('confirmGoodsList') || '[]';
    this.goodsListByVendor = JSON.parse(confirmGoodsList);
    this.calcTotalAmountByVendor(); // 根据档口计算金额
    // 处理讲档口维度的商品列表处理为网仓维度
    const tempGoodsList = cloneDeep(toJS(this.goodsListByVendor));
    const goodsListByWareHouse: PutAwayList[] = [];
    for (const vendor of tempGoodsList) {
      if (!goodsListByWareHouse.length) {
        goodsListByWareHouse.push(vendor);
      } else {
        const warehouse = goodsListByWareHouse.find((el) => el.warehouseId === vendor.warehouseId);
        const vendorItem = goodsListByWareHouse.find((el) => el.vendorId === vendor.vendorId);
        if (warehouse?.warehouseId && warehouse?.warehouseId !== -1) {
          if (warehouse) {
            warehouse.goodsVos?.push(...(vendor.goodsVos || []));
          } else {
            goodsListByWareHouse.push(vendor);
          }
        } else {
          if (vendorItem) {
            vendorItem.goodsVos?.push(...(vendor.goodsVos || []));
          } else {
            goodsListByWareHouse.push(vendor);
          }
        }
      }
    }

    this.goodsListByWareHouse = [...goodsListByWareHouse];
  };

  // 提取无忧退货的内容商品内容
  @action private getWorryFreeGoods = (): void => {
    const worryFreeGoodsList = [];
    this.goodsListByVendor.forEach((el) => { // 遍历档口
      el.goodsVos?.forEach((v) => { // 遍历款式
        // 如果款式支持无忧退货，遍历sku
        if (v.returnable === 2) {
          const worryFreeNum = v?.skuVos?.reduce((pre, cur) => (pre + cur.skuNum), 0);
          const price = Math.min(...(v?.skuVos?.map((el) => el.price) || []));
          const skuDiscountPrice = Math.min(...(v?.skuVos?.map((el) => el.skuDiscountPrice) || []));
          const skuActivityPrice = Math.min(...(v?.skuVos?.map((el) => el.skuActivityPrice) || []));
          worryFreeGoodsList.push({
            picUrl: v.mainPicUrl,
            goodsName: v.goodsName,
            vendorId: el.vendorId,
            goodsId: v.goodsId,
            worryFreeNum,
            goodsNum: 1,
            returnable: v.returnable,
            returnRate: v.returnRate,
            returnPeriod: v.returnPeriod,
            protectRate: v.protectRate,
            price,
            skuDiscountPrice: skuDiscountPrice || null,
            skuActivityPrice: skuActivityPrice || null,
          });
        }
      });
    });

    this.showFreeWorryEntrance = Boolean(worryFreeGoodsList.length);
    this.worryFreeGoodsList = worryFreeGoodsList;
  };

  // 地址tab切换
  @action public changeAddressTab = (tabKey: string) => {
    this.addressTabKey = tabKey;

    // tab切换，清空运费列表
    this.postList = [];
    this.currentAddress = null;
    this.bottomRef?.setFieldsValue({ postFee: null });

    if (tabKey === '1') {
      const address = this.allAddressList.find((item) => item.id === this.mallReceiverAddressId);
      this.currentAddress = address;
      this.getPostFee();
    } else {
      this.parserAddress();

      // 请求运费
      // if (this.currentAddress?.province && this.currentAddress?.city) {
      //   this.getPostFee();
      // }
    }
  };

  // 监听手动创建地址form, 实时回显至底部结算区
  @action public onNewAddressChange = (values): void => {
    try {
      const {
        address,
        phone,
        detail,
        name,
      } = this.newAddRef?.getFieldsValue();
      const addressInfo = this.handleAddress(address);
      this.currentAddress = {
        ...addressInfo,
        receiverName: name,
        receiverAddress: detail,
        receiverMobile: phone,
      };

      if (values?.address) {
        this.parserAddress();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // 解析当前地址
  @action public parserAddress = (): void => {
    const values = this.newAddRef?.getFieldsValue();
    if (!values) {
      return;
    }

    // 地址存在则请求运费
    if (values?.address && values.address?.length > 0) {
      const {
        address,
        phone,
        detail,
        name,
      } = values;
      try {
        const addressInfo = this.handleAddress(address);
        this.currentAddress = {
          ...addressInfo,
          receiverName: name,
          receiverAddress: detail,
          receiverMobile: phone,
        };
        this.getPostFee();
      } catch (err) {
        console.log(err);
      }

      // 地址不存在则清空运费信息
    } else {
      this.currentAddress = null;
      this.bottomRef.setFieldsValue({ postFee: null });
      this.postList = [];
      this.fright = 0;
    }
  };

  // 查询运费
  @action public getPostFee = async(): Promise<void> => {
    // 获取需要下单的sku列表
    let skuList = [];
    this.goodsListByVendor?.forEach((vendor) => {
      vendor?.goodsVos?.forEach((goods) => {
        const tempList = goods.skuVos?.map((sku) => {
          return {
            goodsSkuId: sku.goodsSkuId,
            num: sku.skuNum,
          };
        });
        skuList = [
          ...skuList,
          ...tempList,
        ];
      });
    });
    const data = {
      receiverAddressId: null,
      skuToList: skuList,
      province: '',
      city: '',
    };

    if (this.deliveryType === 0) {
      if (this.addressTabKey === '2') { // 新增地址信息
        Object.assign(data, {
          province: this.currentAddress?.province,
          city: this.currentAddress?.city,
        });
        delete data.receiverAddressId;
      } else { // 已有地址信息
        Object.assign(data, { receiverAddressId: this.mallReceiverAddressId });
        delete data.province;
        delete data.city;
      }
    }

    if (skuList?.length && this.deliveryType === 0) {
      const res: BaseData<IObj> = await request({
        url: api.getPosFee,
        method: 'post',
        data,
      });

      const getMinPostfee = (arr: Post[], defaultCourierId: number): number => {
        const currentItem = arr.find((v) => v.courierId === defaultCourierId);
        if (currentItem) {
          return currentItem.postFee;
        }
        return Math.min(...arr.map((v) => v.postFee));
      };

      const validDefaultCourier = (defaultCourierId: number, postList: PostList[]): boolean => {
        const courierItem = postList?.find((v) => v.courierId === defaultCourierId);
        if (defaultCourierId === -1 || !courierItem) {
          return false;
        }
        return true;
      };

      if (res?.data) {
        res.data.courierFeeTos?.map((item) => {
          const courierFeeTosTmp = item.courierFeeTos.sort((a, b) => a.postFee - b.postFee);
          const isExistDefaultCourierId = validDefaultCourier(item.defaultCourierId, item.courierFeeTos);
          const defaultCourierId = isExistDefaultCourierId ? item.defaultCourierId : courierFeeTosTmp?.[0].courierId;
          Object.assign(this.wmsToCourier, {
            [item.cloudWmsId]: {
              defaultCourierId: item.defaultCourierId,
              currentCourierId: (item.defaultCourierId !== -1 ? defaultCourierId : courierFeeTosTmp?.[0].courierId) || undefined,
              wmsName: item.cloudWmsName,
              postList: item.courierFeeTos,
              postFee: getMinPostfee(item.courierFeeTos || [], defaultCourierId),
              type: 'cloud',
            },
          });
        });
        res.data.supplierCourierFeeTos?.map((item) => {
          const courierFeeTosTmp = item.courierFeeTos.sort((a, b) => a.postFee - b.postFee);
          const isExistDefaultCourierId = validDefaultCourier(item.defaultCourierId, item.courierFeeTos);
          const defaultCourierId = isExistDefaultCourierId ? item.defaultCourierId : courierFeeTosTmp?.[0].courierId;

          Object.assign(this.wmsToCourier, {
            [item.shopId]: {
              defaultCourierId: item.defaultCourierId,
              currentCourierId: (item.defaultCourierId !== -1 ? defaultCourierId : courierFeeTosTmp?.[0].courierId) || undefined,
              wmsName: `${item.shopName}-供应商发货`,
              postList: item.courierFeeTos,
              postFee: getMinPostfee(item.courierFeeTos || [], defaultCourierId),
              type: 'vendor',
            },
          });
        });
        this.hasPostFee = res?.data.hasPostFee;
        this.perBillingProjectCosts = res?.data.perBillingProjectCosts;
        this.totalPerBillingProjectCosts = this.perBillingProjectCosts?.reduce((pre, next) => {
          return pre + next.amount;
        }, 0);

        // // 请求运费列表
        // const postList: PostList[] = res?.data?.courierFeeTos?.map((el) => {
        //   let fright = el.postFee;
        //   if (this.postAmountDiscount?.hasCoupon) { // 首单免运费
        //     fright = el.postFee - this.postAmountDiscount?.discountAmount;
        //   }
        //   return {
        //     label: `${el.courierName}(¥${el.postFee})`,
        //     value: el.courierId,
        //     postFee: fright < 0 ? 0 : fright,
        //     originPostFee: el.postFee,
        //     cpCode: el.cpCode,
        //   };
        // });
        // const minimum = postList?.reduce((pre, cur) => {
        //   if (cur.originPostFee < pre.originPostFee) {
        //     return {
        //       value: cur.value,
        //       originPostFee: cur.originPostFee,
        //       postFee: cur.postFee,
        //     };
        //   }
        //   return pre;
        // }, {
        //   value: postList[0]?.value || null,
        //   postFee: postList[0]?.postFee || 0,
        //   originPostFee: postList[0]?.originPostFee || 0,
        // });
        // this.postList = postList;
        // this.bottomRef?.setFieldsValue({ postFee: minimum?.value });

        let fee = 0;
        Object.keys(this.wmsToCourier)
          .map((key) => {
            fee += this.wmsToCourier[key]?.postFee;
          });
        this.fright = fee;
        if (this.postAmountDiscount?.hasCoupon) { // 首单免运费
          this.fright -= this.postAmountDiscount?.discountAmount;
          if (this.fright < 0) {
            this.fright = 0;
          }
        }
      }
    }
  };

  // 查询首单免运费
  @action public queryPostAmountDiscount = async(): Promise<void> => {
    const res: BaseData<{
      hasCoupon: boolean;
      discountAmount: number;
    }> = await request({ url: api.queryPostAmountDiscount });
    this.postAmountDiscount = res.data || this.postAmountDiscount;
  };

  // 新建常用收货地址
  @action public createCommonAddress = async(): Promise<BaseData<string>> => {
    const {
      name,
      phone,
      address,
      detail,
    } = this.newAddRef?.getFieldsValue();
    const addressInfo = this.handleAddress(address);
    const params = {
      receiverName: name,
      receiverMobile: phone,
      receiverAddress: detail,
      defaultAddress: 0,
      ...addressInfo,
    };
    const res: BaseData<string> = await request({
      url: api.addReceiverAddress,
      method: 'post',
      data: params,
    });
    return Promise.resolve(res);
  };

  @observable public worryFreePurchaseStore = new WorryFreePurchaseStore({
    parent: this,
    frontOrBack: 'front',
    confirmCallBack: this.setSelectedWorryFreeList,
  });

  private backTop = (): void => {
    scrollToAnchor('address');
  };

  public editAddressWriteBack = (params: Partial<Address>): void => {
    const {
      mallReceiverAddressId,
      receiverAddress,
      receiverMobile,
      receiverName,
      province,
      city,
      district,
    } = params;
    const idx = this.allAddressList.findIndex((item) => item.id === mallReceiverAddressId);
    if (idx > -1) {
      this.allAddressList[idx] = {
        ...this.allAddressList[idx],
        receiverName,
        receiverMobile,
        receiverAddress,
        province,
        city,
        district,
      };
      const index = this.addressList.findIndex((item) => item.id === mallReceiverAddressId);
      if (index > -1) {
        this.addressList[idx] = {
          ...this.addressList[idx],
          receiverName,
          receiverMobile,
          receiverAddress,
          province,
          city,
          district,
        };
      }
      if (this.mallReceiverAddressId === mallReceiverAddressId) {
        this.currentAddress = this.allAddressList[idx];
      }
    }
  };
}
