import type { BaseData, IObj } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { api, checkLoginStatus, getDateInterval, replaceBar } from '../../utils';
import { FilterStore } from '../components/filter/store';
import type { Market, TagGroup } from '../components/goodsFilter/interface';
import { HeaderStore } from '../components/headerSearch/store';
import { ImageSearchStore } from '../components/imageSearch/store';
import PaginationStore from '../components/pagination/store';
import { RightSiderStore } from '../components/rightSider/store';
import SordPanelStore from '../components/sordPanel/store';
import type { IActiveLists, ICategory, IData, IDataList, IQuerycondition, ISearchResultFilter } from '../homePage/interface';
import type { ParentFtCategoryProps } from '../market/interface';
import { FILTER_ITEMS, StallFilterItems, TAGS_MAP } from './constant';

interface IStallCategory {
  layoutDetailList: Array<{ id: number; name: string; }>;
}

export class Store {
  constructor() {
    this.getLoginStatus();
    this.getParentFtCategory();
    this.getTagGroup();
    this.getMarket();
    this.getStallCategory();
  }

  @observable public headerStore = new HeaderStore({ parent: this });

  @observable public imageSearchStore = new ImageSearchStore({ parent: this });

  @observable public paginationStore = new PaginationStore({ parent: this });

  @observable public sordPanelStore = new SordPanelStore({ parent: this });

  @observable public rightSiderStore = new RightSiderStore({ parent: this });

  @observable public pageParams: IQuerycondition = {
    page: 1,
    pageSize: 50,
    sidx: '',
    sord: '',
  };

  @observable public searchResultFilter: ISearchResultFilter = {
    cooperateVendor: false,
    shelve: false,
    returnable: false,
    video: false,
  };

  @observable public currentParams: IObj = {};

  @observable public loading = false;

  @observable public searchResult = true;

  @observable public searchImg = false; // 是否图片搜索

  @observable public searchImgUrl = ''; // 图片url

  @observable public dataList = []; // 查询列表数组，适用于款式、档口、图片

  @observable public pageOrigin = 'search'; // 页面入口 active：活动页面，search：文字搜索，searchImg：图片搜索

  @observable public activeId = null;

  @observable public setIntervalInstance: NodeJS.Timeout | null;

  @observable public countDownTime: IObj = {};

  @observable public activeDetail: IActiveLists;

  @observable public totalCount = 0;

  @observable public totalPageCount = 0;

  @observable public currentSord = 'default'; // 当前选中的排序条件

  @observable public loginStatus = false;

  @observable public parentCategoryId = null;

  @observable public ftCategoryIds = null;

  @observable public cityId = null;

  @observable public marketIds = null;

  @observable public businessDistrictId = null;

  @observable public clothesStyle = '';

  @observable public category: ICategory[] = []; // 类目

  @observable public filterParamsTmp: IObj = {}; // 活动页筛选条件

  @action public getLoginStatus = async(): Promise<void> => {
    this.loginStatus = await checkLoginStatus();
    console.log(this.loginStatus, 'this.loginStatus');
  };

  // 处理前端3级类目
  private dealFtCategory = (categoryList: ParentFtCategoryProps[]) => {
    return categoryList?.map((category) => {
      if (!category.child || category.child.length === 0) {
        return {
          label: category.ftCategoryName,
          value: category.id,
          children: [],
        };
      } else {
        return {
          label: category.ftCategoryName,
          value: category.id,
          children: this.dealFtCategory(category.child),
        };
      }
    });
  };

  // 获取品类
  @action
  public getParentFtCategory = async() => {
    const req = await request<BaseData<ParentFtCategoryProps[]>>({
      url: api.queryMoreCategory,
      method: 'get',
    });
    this.category = req.data;
    const ftCategoryIds = this.dealFtCategory(req.data);
    this.filterParams.updateFilterItems({ ftCategoryIds });
  };

  // 处理所选择的品类
  @action
  public getFtCategory = (categoryId: number) => {
    let parentFtCategoryId: number,
      ftCategoryIds = [];
    const currentCategory = this.category?.find((v) => v.id === categoryId);
    if (currentCategory) {
      parentFtCategoryId = categoryId;
    } else {
      for (const value of this.category) {
        const secondList = value.child;
        const secondCategory = secondList?.find((v) => v.id === categoryId);
        if (secondCategory) {
          parentFtCategoryId = value.id;
          ftCategoryIds = [categoryId];
          break;
        } else {
          for (const item of secondList) {
            const thirdCategory = item.child?.find((v) => v.id === categoryId);
            if (thirdCategory) {
              parentFtCategoryId = value.id;
              ftCategoryIds = [categoryId];
              break;
            }
          }
        }
      }
    }
    return {
      parentFtCategoryId,
      ftCategoryIds,
    };
  };

  // 获取档口相关全部分类
  @action
  public getStallCategory = async(): Promise<void> => {
    const req = await request<BaseData<IStallCategory>>({ url: api.getStallCategory });
    const parentFtCategory = [
      ...req.data.layoutDetailList.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    ];
    this.stallFilterItems.updateFilterItems({ mainCategoryId: parentFtCategory });
  };

  // 查询款式标签列表
  @action public getTagGroup = async(): Promise<void> => {
    const res: BaseData<TagGroup[]> = await request({ url: `${api.getTagGroup}?type=3` });
    const tags = res.data;
    const translateData = (arr) => {
      if (!arr || arr.length === 0) {
        return [];
      }
      return arr.map((item) => {
        return {
          value: item.tagId,
          label: item.tagName,
        };
      });
    };

    const obj = {
      style: translateData(tags.find((item) => item.tagGroupId === 10001)?.tags), // 风格
      applicableAge: translateData(tags.find((item) => item.tagGroupId === 100019)?.tags), // 适用年龄
      applicableChannel: translateData(tags.find((item) => item.tagGroupId === 10015)?.tags), // 主销渠道
      applicableSeason: translateData(tags.find((item) => item.tagGroupId === 10017)?.tags), // 适用季节
      applicableGender: translateData(tags.find((item) => item.tagGroupId === 10018)?.tags), // 适用性别
    };
    TAGS_MAP.map((item) => {
      this.filterParams.updateFilterItems({ [item]: obj[item] });
    });
  };

  // 查询市场列表
  @action public getMarket = async(): Promise<void> => {
    const res: BaseData<Market[]> = await request({ url: api.getGoodsMarket });
    const list = res.data.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    this.filterParams.updateFilterItems({ marketIds: list });
  };

  public filterParams = new FilterStore({
    filterItems: FILTER_ITEMS(this),
    handleSearch: () => {
      this.filterParamsTmp = this.filterParams.params;
      this.paginationStore.resetPage();
      const ftCategoryId = this.filterParamsTmp.ftCategoryIds;
      if (ftCategoryId) {
        const {
          parentFtCategoryId,
          ftCategoryIds,
        } = this.getFtCategory(ftCategoryId);
        this.filterParamsTmp.parentFtCategoryId = parentFtCategoryId;
        this.filterParamsTmp.ftCategoryIds = ftCategoryIds;
      }

      this.handleQuery({
        ...this.currentParams,
        page: 1,
        pageSize: 50,
      });
    },
  });

  public stallFilterItems = new FilterStore({
    filterItems: StallFilterItems(this),
    handleSearch: () => {
      this.filterParamsTmp = this.stallFilterItems.params;
      this.paginationStore.resetPage();
      this.handleQuery({
        ...this.currentParams,
        page: 1,
        pageSize: 50,
      });
    },
  });

  @action public resetFilter = (): void => {
    this.filterParams.resetFilterItems(FILTER_ITEMS(this));
    this.stallFilterItems.resetFilterItems(StallFilterItems(this));
  };

  @action public searchPro = (): void => {
    this.paginationStore.resetPage();
    if (this.searchImg) {
      const url = `/egenie-ts-vogue/chooseSquare/search?searchTypeKey=${this.headerStore.searchTypeKey}&searchName=${this.headerStore.searchName}`;
      window.top.egenie.openTab(url, 'chooseSquareSearchResult', '搜索');
    } else {
      this.handleQuery({
        page: 1,
        pageSize: 50,
      });
    }
  };

  @action private getUniqClothes = (clothesType: string[]): string[] => {
    if (typeof clothesType === 'string') {
      return [clothesType];
    }
    if (clothesType && clothesType.length > 0) {
      return Array.from(new Set(clothesType));
    } else {
      return [];
    }
  };

  // 查询列表（款式、档口、图片）
  @action public handleQuery = (params: IObj, searchResultFilter?: ISearchResultFilter): void => {
    this.loading = true;
    this.dataList = [];
    const url = this.searchImg ? api.getSimilarLists : this.headerStore.searchTypeKey === 'style' ? api.getPageGoods : api.findStall;

    let paramsTmp: IObj = {};
    this.searchResultFilter = {
      ...this.searchResultFilter,
      ...searchResultFilter,
    };
    const clothesStyle = this.getUniqClothes(params?.clothesStyle)
      .join(',');
    paramsTmp = {
      ...this.currentParams,
      ...this.pageParams,
      ...params,
      ...this.searchResultFilter,
      clothesStyle: clothesStyle?.toString(),
    };
    this.pageParams = {
      page: paramsTmp?.page || 1,
      pageSize: paramsTmp?.pageSize || 50,
      sord: paramsTmp?.sord || '',
      sidx: paramsTmp?.sidx || '',
    };
    this.currentSord = paramsTmp?.sidx;
    this.currentParams = paramsTmp;
    if (!this.searchImg) {
      paramsTmp.keyword = this.headerStore.searchName;
    } else {
      paramsTmp = {
        ...paramsTmp,
        pageSize: 100,
        pirUrl: this.searchImgUrl,
      };
    }
    if (this.activeId) {
      paramsTmp = {
        ...paramsTmp,
        ...this.filterParamsTmp,
        promotionId: this.activeId,
      };
    }
    if (this.headerStore.searchTypeKey === 'stall') {
      paramsTmp = {
        ...paramsTmp,
        ...this.filterParamsTmp,
        search: this.headerStore.searchName,
        parentFtCategoryId: undefined,
        pageSize: 10,
      };
      delete paramsTmp.keyword;
    }
    if (paramsTmp.sidx === 'priceLow' || paramsTmp.sidx === 'priceHigh') {
      paramsTmp.sord = paramsTmp.sidx === 'priceLow' ? 'asc' : 'desc';
      paramsTmp.sidx = 'productPrice';
    }
    if (paramsTmp.prices && paramsTmp.prices.length > 0) {
      paramsTmp.minProductPrice = paramsTmp.prices[0];
      paramsTmp.maxProductPrice = paramsTmp.prices[1];
      delete paramsTmp.prices;
    }

    if (this.searchImg) {
      this.getImgList(url, paramsTmp);
    } else {
      this.getDataList(url, paramsTmp);
    }
  };

  @action public getImgList = (url: string, params: IObj): void => {
    request<BaseData<IDataList[]>>({
      url,
      method: 'post',
      data: params,
    })
      .then((res) => {
        this.loading = false;
        this.dataList = res.data;
      })
      .catch(() => {
        this.loading = false;
        this.dataList = [];
        this.totalCount = 0;
        this.totalPageCount = 0;
      });
  };

  @action public getDataList = (url: string, params: IObj): void => {
    request<BaseData<IData<IDataList>>>({
      url,
      method: 'post',
      data: params,
    })
      .then((res) => {
        this.loading = false;
        const {
          list,
          totalCount,
          totalPageCount,
        } = res.data;
        this.dataList = list || [];
        this.totalCount = totalCount;
        this.totalPageCount = totalPageCount;
      })
      .catch(() => {
        this.loading = false;
        this.dataList = [];
        this.totalCount = 0;
        this.totalPageCount = 0;
      });
  };

  // 分页
  @action public onPageChange = (page: number, pageSize: number): void => {
    this.handleQuery({
      ...this.currentParams,
      page,
      pageSize,
    });
  };

  // 切换页数
  @action public onShowSizeChange = (page: number, pageSize: number): void => {
    this.handleQuery({
      ...this.currentParams,
      page: 1,
      pageSize,
    });
  };

  // 合并url传过来的参数给store
  @action public setParams = (params): void => {
    Object.assign(this, { ...params });

    if (this.activeId) {
      this.getActiveList();
    } else {
      const searchName = this.headerStore.searchName;
      const title = `${searchName} ${searchName ? '-' : ''} 衫海精搜索`;
      document.title = title;
    }
    const {
      clothesStyle = '',
      parentCategoryId = '',
      ftCategoryIds = '',
      businessDistrictId = '',
      cityId = '',
      marketIds = '',
    } = params;
    this.clothesStyle = clothesStyle;
    this.parentCategoryId = parentCategoryId ? Number(parentCategoryId) : null;
    this.ftCategoryIds = ftCategoryIds ? [Number(ftCategoryIds)] : null;
    this.cityId = cityId ? Number(cityId) : null;
    this.marketIds = businessDistrictId ? [Number(businessDistrictId)] : null;
    this.businessDistrictId = businessDistrictId ? [Number(businessDistrictId)] : null;
    this.currentParams = {
      ...this.currentParams,
      page: 1,
      pageSize: 50,
      businessDistrictId,
    };
    if (this.searchImg && !this.searchImgUrl) {
      return;
    }
    this.handleQuery({
      page: 1,
      pageSize: 50,
      clothesStyle,
    });
  };

  @action public getActiveList = (): void => {
    request<BaseData<IActiveLists>>({ url: `${api.getActiveDetail}?promotionId=${this.activeId}` })
      .then((res) => {
        this.activeDetail = res.data || null;
        const title = `${res.data?.name} ${res.data?.name ? '-' : ''} 衫海精`;
        document.title = title;
        if (this.activeDetail.beginTime && this.activeDetail.endTime) {
          this.getCountDownTime(replaceBar(this.activeDetail.beginTime), replaceBar(this.activeDetail.endTime));
        }
      })
      .catch(() => {
        this.activeDetail = null;
      });
  };

  @action public setLoading = (): void => {
    this.loading = false;
  };

  @action public handleVisibleSearchChange = (flag: boolean, type: string): void => {
    this[type] = flag;
  };

  // 顶部搜索，把当前条件传入
  @action public topSearch = () => {
    this.paginationStore.resetPage();
    this.handleQuery({
      ...this.currentParams,
      page: 1,
      pageSize: 50,
    }, this.searchResultFilter);
  };

  @action public getCountDownTime = (startTime: string, endTime: string): void => {
    this.setIntervalInstance = setInterval(() => {
      const {
        days,
        hours,
        minutes,
        seconds,
        distance,
        currentActivity,
      } = getDateInterval(startTime, endTime);
      Object.assign(this.countDownTime, {
        days: this.adjustTime(days),
        hours: this.adjustTime(hours),
        minutes: this.adjustTime(minutes),
        seconds: this.adjustTime(seconds),
        lastEndTime: distance,
        currentActivity,
      });
      if (distance < 0) {
        clearInterval(this.setIntervalInstance);
      }
    }, 1000);
  };

  @action public adjustTime = (time: number): string => {
    if (time < 10) {
      return `0${time}`;
    }
    return `${time}`;
  };

  @action public onSearch = (filterData) => {
    console.log('searchResult: store 251 line: filterData', filterData);
    this.paginationStore.resetPage();
    this.handleQuery({
      ...filterData,
      page: 1,
      pageSize: 50,
      sidx: '',
      sord: '',
    }, this.searchResultFilter);
  };
}
